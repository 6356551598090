.admission-form {
  margin-top: 2rem;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-form-item-label {
    label {
      font-weight: bold;
    }
  }

  .ant-form-item-control-input {

    input,
    .ant-select-selector {
      border-radius: 4px;
    }
  }

  .ant-btn-primary {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
  }
}


.seat-map-container {
  max-height: 400px;
  /* Adjust based on your modal size */
  overflow-y: auto;
}