.seat-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 5px; /* Add some space between the seats */
    width: 800px; /* Adjust based on the number of seats per row */
    margin: auto;
    background: #f0f0f0; /* Optional: Adds a background to the seat map for better visibility */
    padding: 10px; /* Adds some padding around the seats for better layout */
}

.seat {
    width: 30px; /* Adjust the seat size */
    height: 30px; /* Adjust the seat size */
    background-color: #c8c8c8; /* Default seat color */
    border: 1px solid #999; /* Adds a border to each seat */
    cursor: pointer; /* Changes the cursor to a pointer to indicate the seat can be selected */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px; /* Optional: adds rounded corners to the seats */
}

.seat.selected {
    background-color: #4CAF50; /* Changes the seat color when selected */
}

/* Additional styling for better visibility and interactivity */
.seat:hover {
    background-color: #a5d6a7; /* Lightens the seat color on hover, adjust color as needed */
}

/* You might want to add some responsiveness to the seat map for better layout on different screen sizes */
@media (max-width: 768px) {
    .seat-map {
        width: 100%; /* Makes the seat map full width on smaller screens */
        justify-content: center; /* Centers the seats when the map width changes */
    }
}
