.termsContainer {
    margin-top: 2rem;
    padding: 20px;
    max-width: 800px;
    margin: 2rem auto;
    /* This sets top and bottom margin to 2rem, and left and right margins to auto */
    overflow-x: auto;
}

/* Responsive styling */
@media (max-width: 768px) {
    .termsContainer {
        padding: 10px;
    }
}

@media (max-width: 576px) {
    .termsContainer {
        padding: 5px;
    }
}