.forms-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 5rem 1rem;
  }
  
  .form-wrapper {
    flex: 1;
    min-width: 280px; /* Ensure the form does not get too narrow on smaller screens */
    max-width: 600px; /* Optional: limit the maximum width of the form */
    margin: 1rem;
  }
  
  @media (max-width: 768px) {
    .forms-container {
      flex-direction: column;
      align-items: center;
    }
  }
  