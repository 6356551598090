/* .seat {
    width: 30px;
    height: 30px;
    margin: 5px;
    background-color: #ccc;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.seat.selected {
    background-color: #4CAF50;
}
 */


.seat {
    width: 30px;
    height: 30px;
    margin: 5px;
    background-color: #ccc;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s;
}

.seat.selected {
    background-color: #4CAF50;
    /* Green for current selection */
}

.seat.previously-selected {
    background-color: #FF0000;
    /* Red for previously selected seats */
}