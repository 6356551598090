.verify-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .button-group {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adds space between buttons */
  }
  
  .button-group .ant-btn-primary {
    background-color: #1890ff; /* Active button color */
    border-color: #1890ff; /* Match border color */
  }
  
  .button-group .ant-btn-default {
    background-color: #fff; /* Inactive button color */
    border-color: #d9d9d9; /* Match border color */
  }
  
  .content {
    width: 100%;
    max-width: 600px; /* Adjust based on your preference */
    border: 1px solid #ccc;
    padding: 20px;
    box-sizing: border-box;
  }
  .note {
    margin-top: 50px;
    padding: 10px;
    font-size: 0.9rem; /* Adjust the font size as needed */
    text-align: center; /* Center-align the note */
    color: #666; /* A softer text color for notes */
    background-color: rgb(241, 190, 190);
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .button-group {
      flex-direction: column;
    }
  }
  