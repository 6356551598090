.offer-letter-container {
    display: flex;
    flex-direction: column;
    align-items: start; /* Align items to the start for the label to align nicely */
    gap: 20px;
    padding: 20px;
  }
  
  .verify-button {
    display: flex;
    /* Style for the verify button if needed */
  }
  
  .result {
    margin-top: 20px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure result area is full width */
  }
  
  .label {
    margin-bottom: 8px; /* Adjust based on your preference */
    font-weight: bold;
  }
  
  