.privacyPolicyContainer {
    margin-top: 2rem;
    padding: 20px;
    max-width: 800px;
    margin: 2rem auto;
    /* This sets top and bottom margin to 2rem, and left and right margins to auto */
    overflow-x: auto;
    /* Allows horizontal scrolling if content is wider than screen */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .privacyPolicyContainer {
        padding: 15px;
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    .privacyPolicyContainer {
        padding: 10px;
    }
}

/* Add more media queries as needed for different breakpoints */