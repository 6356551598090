.tutor-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: -40px;
  /* To bring the image halfway up */
}

.card-body {
  padding: 16px;
  text-align: center;
}

.card-title {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.card-text {
  font-size: 0.9em;
  color: #555;
}


/* TutorProfile.css */
.filter-container {
  padding-left: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: start;
}

.filter-container>div {
  flex: 1 1 200px;
  /* Flex-grow, Flex-shrink, Flex-basis */
}

.filter-container select,
.filter-container button {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
}

.filter-container button {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.filter-container button.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter-container {
    padding-left: 0rem;
  }


  .filter-container>div {
    flex: 1 1 100%;
    /* Full width on smaller screens */
  }
}


/* Responsive adjustments */
@media (max-width: 600px) {
  .tutor-cards-container {
    margin: 0;
  }

  .tutor-card {
    max-width: 100%;

  }

}



.tutor-cards-container {
  margin: 2.5rem auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  /* Centering the cards */
}

.tutor-card {
  flex: 0 1 250px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .tutor-cards-container {
    margin: 2.5rem 20px;
    /* Reduced side margins on smaller screens */
  }

  .tutor-card {
    flex-basis: calc(50% - 20px);
    /* Each card takes up roughly half the container width minus the gap */
  }
}

@media (max-width: 600px) {
  .tutor-card {
    flex-basis: calc(100% - 20px);
    /* Each card takes full width of the container minus the gap on very small screens */
  }
}

/* Tutor card image and other styles remain the same */
.tutor-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: -40px;
}

.card-body {
  padding: 16px;
  text-align: center;
}

.card-title {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.card-text {
  font-size: 0.9em;
  color: #555;
}

.no-tutors-message {
  text-align: center;
  margin-top: 20px;
  font-size: 1.5rem;
  color: #666;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* High z-index */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5rem;
}


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}


.teaching-city {
  /* color: #2fff00; */
  margin-top: 8px;
  /* Add margin for spacing */
  font-weight: bold;
  /* Optional: Apply bold font to teachingCity */
}





.tutor-profile-card {
  max-width: 300px;
  /* Adjust width as needed */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.tutor-profile-card .tutor-image-container {
  position: relative;
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
}

.tutor-profile-card .tutor-image-container .overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px; /* Adjust icon size as needed */
  color: white; /* Adjust icon color as needed */
  z-index: 10;
  cursor: pointer;
}

.tutor-profile-card .tutor-image-container {
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
}

.tutor-profile-card .tutor-image-container .tutor-image {
  cursor: pointer;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.tutor-profile-card .tutor-image-container .teaching-city {
  margin-top: 10px;
  font-weight: bold;
}

.tutor-profile-card .tutor-details {
  padding: 20px;
}

.tutor-profile-card .tutor-details .tutor-name {
  font-size: 18px;
  margin-bottom: 10px;
}

.tutor-profile-card .tutor-details .tutor-info {
  font-size: 14px;
  margin-bottom: 10px;
}

.tutor-profile-card .tutor-details .tutor-info .contact-btn {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.tutor-profile-card .tutor-details .tutor-info .contact-btn:hover {
  background-color: #40a9ff;
}



/* pagination css */

.pagination-container {
  text-align: center;
  margin-top: 20px; /* Or any other value for spacing */
  margin-bottom: 10px;
}

.pagination-container .ant-pagination {
  display: inline-block;
}
