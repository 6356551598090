/* InteractionsAdminView.module.css */

.searchInput {
    width: 100%;
    margin-bottom: 16px;
}

.overviewTitle {
    margin-bottom: 16px;
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .searchInput {
        /* Adjustments for extra-small devices (e.g., phones) */
        font-size: 14px;
        padding: 8px;
    }

    .overviewTitle {
        font-size: 18px;
        /* Smaller font size for small screens */
        text-align: center;
        /* Center title for small screens */
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .searchInput {
        /* Adjustments for small devices (e.g., tablets in portrait mode) */
        font-size: 16px;
        padding: 10px;
    }

    .overviewTitle {
        font-size: 20px;
        text-align: left;
        /* Align title to left for larger screens */
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .searchInput {
        /* Adjustments for medium devices (e.g., tablets in landscape mode) */
        font-size: 18px;
        padding: 12px;
    }

    .overviewTitle {
        font-size: 22px;
    }
}

@media (min-width: 993px) {
    .searchInput {
        /* Adjustments for large devices (e.g., desktops) */
        font-size: 20px;
        padding: 15px;
    }

    .overviewTitle {
        font-size: 24px;
        margin-bottom: 20px;
    }
}